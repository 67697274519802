<template>
  <Teleport to="body">
    <div
      ref="modalRef"
      tabindex="-1"
      aria-hidden="true"
      class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[60] w-full md:inset-0 h-modal md:h-full"
    >
      <div
        class="relative p-4 w-full h-full md:h-auto"
        :class="{
          'max-w-md': modalSize === 'small',
          'max-w-lg': modalSize === 'medium',
          'max-w-3xl': modalSize === 'default',
          'max-w-5xl': modalSize === 'large',
          'max-w-7xl': modalSize === 'xlarge',
        }"
      >
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
          <!-- Modal header -->
          <div
            class="flex justify-between items-start p-4 rounded-t"
            :class="{
              'border-b': !hideBorders,
            }"
          >
            <div class="flex items-center gap-x-2">
              <div
                v-if="icon"
                class="p-1.5 rounded-lg"
                :class="{
                  'bg-red-100 text-red-600': modalType === EStatusColor.Danger,
                }"
              >
                <component :is="icon" class="w-5 h-5" />
              </div>

              <h3 class="text-xl font-semibold text-gray-900">{{ title }}</h3>
            </div>

            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center transition-colors"
              @click="closeModal"
            >
              <XMarkIcon class="w-5 h-5" />
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body p-6 space-y-6 overflow-y-auto" :class="[bodyClass]">
            <slot />
          </div>

          <!-- Modal footer -->
          <div
            v-if="!hideFooter"
            class="flex items-center p-6 space-x-2 rounded-b border-gray-200"
            :class="{ 'justify-center': hideValidation, 'justify-between': !hideValidation, 'border-t': !hideBorders }"
          >
            <slot name="modal-footer">
              <BaseButton
                class="simple"
                :track-object="trackObjectClose ?? $trackedEvents.noTracking"
                :track-props="trackProps"
                @click="closeModal"
              >
                {{ closeText }}
              </BaseButton>

              <BaseButton
                v-if="!hideValidation"
                :class="[
                  modalType,
                  {
                    primary: !modalType,
                  },
                ]"
                :is-loading="isLoading"
                :disabled="isOkDisabled"
                :track-object="trackObjectOk ?? $trackedEvents.noTracking"
                :track-props="trackProps"
                @click="$emit('validate')"
              >
                {{ okText }}
              </BaseButton>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import BaseButton from '@/components/base/button/BaseButton.vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'

import { computed, FunctionalComponent, onMounted, ref, watch } from 'vue'
import { Modal } from 'flowbite'
import { useI18n } from 'vue-i18n'

import { IEvent } from '@/utils/tracking'
import type { ModalInterface } from 'flowbite'
import { EStatusColor } from '@/types/StatusColor.enum'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    isVisible: boolean
    title?: string
    closeText?: string
    okText?: string
    modalSize?: 'small' | 'medium' | 'default' | 'large' | 'xlarge'
    bodyClass?: string
    isOkDisabled?: boolean
    hideValidation?: boolean
    hideFooter?: boolean
    isLoading?: boolean
    modalType?: EStatusColor
    icon?: FunctionalComponent
    trackObjectOk?: IEvent
    trackObjectClose?: IEvent
    trackProps?: Record<string, string | undefined>
    hideBorders?: boolean
  }>(),
  {
    modalSize: 'large',
  },
)

const closeText = computed(() => props.closeText ?? t('common.modal.close'))
const okText = computed(() => props.okText ?? t('common.modal.ok'))

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'validate'): void
}>()

const modalRef = ref<HTMLElement | null>(null)
const modal = ref<ModalInterface | null>(null)

onMounted(() => {
  if (modalRef.value) {
    modal.value = new Modal(modalRef.value, {
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[59]', // default tailwind classes + different z-index ; TODO: remove tailwind
      backdrop: 'dynamic',
      closable: false,
    })
  }
})

const openModal = () => {
  if (modal.value) {
    modal.value.show()
  }
}

const closeModal = () => {
  if (modal.value) {
    modal.value.hide()

    if (props.isVisible) {
      emit('close')
    }
  }
}

watch(
  () => props.isVisible,
  () => {
    if (props.isVisible) {
      openModal()
    } else {
      closeModal()
    }
  },
)
</script>

<style scoped>
.modal-body {
  max-height: calc(100vh - 2rem - 65px - 87px);
}
</style>
