// TODO: it will be deleted

import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from "./service.helper"

import AccountsQuery from "@/schemas/Accounts.query.gql"

import IAccount from "@/types/Account.interface"
import IAccountsQuery from '@/types/schemas/AccountsQuery.interface'

const { getProjectId } = useServiceHelper()

const getAccounts = async (): Promise<IAccount[]> => {
  const { data } = await useQuery<IAccountsQuery>({
    query: AccountsQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return data.accounts
}

export const useAccountsService = () => ({
  getAccounts,
})
