import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import ScoringQuery from '@/schemas/Scoring.query.gql'

import IScoring from '@/types/Scoring.interface'
import IScoringQuery from '@/types/schemas/ScoringQuery.interface'

const { getActiveRequestId, getProjectId } = useServiceHelper()

const getScoring = async (): Promise<IScoring | null> => {
  const {
    data: { scoring },
  } = await useQuery<IScoringQuery>({
    query: ScoringQuery,
    variables: {
      id: getProjectId(),
      requestId: getActiveRequestId(),
    },
  })

  return scoring
}

export const useScoringService = () => ({
  getScoring,
})
