import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import IncomesCalculationRecommendationQuery from '@/schemas/IncomesCalculationRecommendation.query.gql'

import IIncomesCalculationRecommendation from '@/types/IncomesCalculationRecommendation.interface'
import IIncomesCalculationRecommendationQuery from '@/types/schemas/IncomesCalculationRecommendationQuery.interface'

const { getActiveRequestId, getProjectId } = useServiceHelper()

const getIncomesCalculationRecommendation = async (): Promise<IIncomesCalculationRecommendation[]> => {
  const { data } = await useQuery<IIncomesCalculationRecommendationQuery>({
    query: IncomesCalculationRecommendationQuery,
    variables: {
      id: getProjectId(),
      requestId: getActiveRequestId(),
    },
  })

  return data.incomesCalculationRecommendation
}

export const useIncomesCalculationService = () => ({
  getIncomesCalculationRecommendation,
})
