import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import AdvicesQuery from '@/schemas/Advices.query.gql'

import IAdvice from '@/types/Advice.interface'
import IAdvicesQuery from '@/types/schemas/AdvicesQuery.interface'

const { getProjectId } = useServiceHelper()

const getAdvices = async (): Promise<IAdvice[]> => {
  const { data } = await useQuery<IAdvicesQuery>({
    query: AdvicesQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return data.advices
}

export const useAdvicesService = () => ({
  getAdvices,
})
