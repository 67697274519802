import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import DealQuery from '@/schemas/Deal.query.gql'

import IDeal from '@/types/Deal.interface'
import IDealQuery from '@/types/schemas/DealQuery.interface'

const { getProjectId } = useServiceHelper()

const getDeal = async (): Promise<IDeal> => {
  const { data } = await useQuery<IDealQuery>({
    query: DealQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return data.deal
}

export const useDealService = () => ({
  getDeal,
})
