export enum EExtraIncome {
  Rent = 'rent',
  ProfessionalBenefits = 'professional_benefits',
  Dividend = 'dividend',
  ChildSupport = 'child_support',
  MilitaryPension = 'military_pension',
  InvalidityPension = 'invalidity_pension',
  FamilyAllowances = 'family_allowances',
  ExpatriationBonus = `expatriation_bonus`,
  CommercialRent = 'commercial_rent',
  SeasonalRent = 'seasonal_rent',
  ExpectedRentalIncome = 'expected_rental_income',
  CompensatoryAllowances = 'compensatory_allowances',
  HousingAllowances = 'housing_allowances',
  PropertyManagementCompany = 'property_management_company',
  PropertyInvestmentCompany = 'property_investment_company',
}
