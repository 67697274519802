import { defineStore } from 'pinia'
import { uniqBy } from 'lodash'
import { useAccountsService } from '@/services/accounts.service'
import { useAdvicesService } from '@/services/advices.service'
import { useBankAccountService } from '@/services/bank_account.service'
import { useBankContactService } from '@/services/bank_contact.service'
import { useBankService } from '@/services/bank.service'
import { useBankStatementService } from '@/services/bank_statement.service'
import { useBrokerService } from '@/services/broker.service'
import { useBrokerageFeesService } from '@/services/brokerage_fees.service'
import { useDealService } from '@/services/deal.service'
import { useDocumentService } from '@/services/document.service'
import { useFinancingService } from '@/services/financing.service'
import { useIncomesCalculationService } from '@/services/incomes_calculation.service'
import { useInsuranceService } from '@/services/insurance.service'
import { useInterrogationService } from '@/services/interrogation.service'
import { useNotificationService } from '@/services/notification.service'
import { usePrequalificationService } from '@/services/prequalification.service'
import { useScoringService } from '@/services/scoring.service'

import TFinancing, { EFinancingState } from '@/types/Financing.type'
import TNotification from '@/types/Notification.type'
import IAccount from '@/types/Account.interface'
import IAdvice from '@/types/Advice.interface'
import IBankAccount from '@/types/BankAccount.interface'
import IBankContact from '@/types/BankContact.interface'
import IBankDetails from '@/types/BankDetails.interface'
import IBankStatementsAnalysis from '@/types/BankStatementsAnalysis.interface'
import IBankStatementsAnalysisInput from '@/types/schemas/Preparation/BankStatementsAnalysisInput.interface'
import IBroker from '@/types/Broker.interface'
import IBrokerageFees from '@/types/BrokerageFees.interface'
import IDeal from '@/types/Deal.interface'
import IDocument from '@/types/Document.interface'
import IDocumentFile from '@/types/DocumentFile.interface'
import IFileBatch from '@/types/FileBatch.interface'
import IProject from '@/types/Project.interface'
import IIncomesCalculationRecommendation from '@/types/IncomesCalculationRecommendation.interface'
import IInterrogation from '@/types/Interrogation.interface'
import IPrequalification from '@/types/Prequalification.interface'
import IRequest from '@/types/Request.interface'
import IScoring from '@/types/Scoring.interface'
import { EBatchStrategyName } from '@/types/BatchStrategyName.enum'
import { EFileClassName } from '@/types/FileClassName.enum'

const { getAccounts } = useAccountsService()
const { getAdvices } = useAdvicesService()
const { getBankAccounts } = useBankAccountService()
const { getBankContacts } = useBankContactService()
const { getBankList } = useBankService()
const { getBankStatementsAnalysis } = useBankStatementService()
const { getDeal } = useDealService()
const { getDocsCompletion, getDocuments, getLastDocNotificationAt } = useDocumentService()
const { getFinancing } = useFinancingService()
const { getBrokerageFees } = useBrokerageFeesService()
const { getIncomesCalculationRecommendation } = useIncomesCalculationService()
const { getDigitalInsureProjectId } = useInsuranceService()
const { getInterrogations } = useInterrogationService()
const { getNotifications } = useNotificationService()
const { getPrequalifications } = usePrequalificationService()
const { getBroker } = useBrokerService()
const { getScoring } = useScoringService()

interface IMainStore {
  accounts: IAccount[]
  advices: IAdvice[]
  areDocumentsLoading: boolean
  bankAccounts: IBankAccount[]
  bankContacts: IBankContact[]
  bankStatementsAnalysis: IBankStatementsAnalysis[]
  banks: IBankDetails[]
  broker: IBroker | null
  brokerageFees: IBrokerageFees
  deal: IDeal
  docsCompletion: number
  documents: IDocument[]
  financing: TFinancing
  incomesCalculationRecommendation: IIncomesCalculationRecommendation[]
  interrogations: IInterrogation[]
  isBudgetModeActive: boolean
  prequalifications: IPrequalification[]
  notifications: TNotification[]
  project: IProject
  projectId: string | null
  scoring: IScoring | null
  usedFinancing: TFinancing
  requests: IRequest[]
  activeRequestId: string | null
}

export const useStore = defineStore('main', {
  state: (): IMainStore => {
    return {
      accounts: [],
      advices: [],
      areDocumentsLoading: false,
      bankAccounts: [],
      bankContacts: [],
      bankStatementsAnalysis: [],
      banks: [],
      broker: null,
      brokerageFees: {} as IBrokerageFees,
      deal: {} as IDeal,
      docsCompletion: 0,
      documents: [],
      financing: {} as TFinancing,
      incomesCalculationRecommendation: [],
      interrogations: [],
      isBudgetModeActive: false,
      prequalifications: [],
      notifications: [],
      project: {} as IProject,
      projectId: null,
      scoring: {} as IScoring,
      usedFinancing: {} as TFinancing,
      requests: [],
      activeRequestId: null,
    }
  },

  getters: {
    selectedBank(): IBankDetails | undefined {
      return this.request.decisions.bankSlug ? this.getBank(this.request.decisions.bankSlug) : undefined
    },

    getBank: (state: IMainStore): ((slug: string) => IBankDetails | undefined) => {
      return (slug) => state.banks.find((bank) => bank.slug === slug)
    },

    mortgagorsNames(): { value: number; label: string }[] {
      return this.project.profile.mortgagors.map((mortgagor, index) => {
        return {
          value: index,
          label: mortgagor.first_name ? `${mortgagor.first_name} ${mortgagor.last_name}` : mortgagor.last_name,
        }
      })
    },

    mortgagorsCount: (state: IMainStore): number =>
      state.project.profile.mortgagors.filter((mortgagor) => !mortgagor._delete).length,

    isMortgagorsDataEmpty: (state: IMainStore): boolean =>
      state.accounts.length !== state.project.profile.mortgagors.length,
    hasMortgagorAccount:
      (state: IMainStore) =>
      (mortgagorId: string): boolean =>
        state.accounts.some((account) => account.mortgagorId === mortgagorId),

    files: (state: IMainStore): IDocumentFile[] => state.documents.flatMap((doc) => doc.files),

    filteredFiles(state: IMainStore): IDocumentFile[] {
      return state.documents
        .flatMap((doc) => doc.files)
        .filter((file) => {
          if (!file.batch?.id) return true

          const isBatchTreated = file.batch.treatedAt

          return file.className === EFileClassName.Result || !isBatchTreated
        })
    },

    batches: (state: IMainStore): IFileBatch[] =>
      state.documents
        .flatMap((doc) => doc.files)
        .reduce<IFileBatch[]>((list, file) => {
          if (file.batch && !list.find(({ id }) => file.batch!.id === id)) {
            list.push(file.batch)
          }

          return list
        }, []),

    pendingBatches(): string[] {
      return this.batches
        .filter((batch) => batch.strategyName === EBatchStrategyName.TerseaStrategy && !batch.treatedAt)
        .map((batch) => batch.id)
    },

    request: (state: IMainStore): IRequest => state.requests.find(({ id }) => id === state.activeRequestId)!,
    childSupportSubstractedFromIncome(): boolean | undefined {
      return this.financing.state === EFinancingState.Valid
        ? this.financing.childSupportSubstractedFromIncome
        : undefined
    },
  },

  actions: {
    async fetchBrokerageFees(): Promise<void> {
      this.brokerageFees = structuredClone(await getBrokerageFees())
    },

    async fetchAccounts(): Promise<void> {
      this.accounts = await getAccounts()
    },

    async fetchAdvices(): Promise<void> {
      this.advices = await getAdvices()
    },

    async fetchDeal(): Promise<void> {
      this.deal = structuredClone(await getDeal())
    },

    async fetchDocsCompletion(): Promise<void> {
      this.docsCompletion = await getDocsCompletion()
    },

    async refreshData(): Promise<void> {
      await Promise.allSettled([
        this.fetchAdvices(),
        this.fetchDocsCompletion(),
        this.fetchFinancing(),
        this.fetchIncomesCalculationRecommendation(),
        this.fetchScoring(),
      ])
    },

    async fetchFinancing(): Promise<void> {
      const financing = await getFinancing()

      if ('error' in financing) {
        this.financing = {
          ...structuredClone(financing),
          state: EFinancingState.Error,
        }
      } else {
        this.financing = {
          ...structuredClone(financing),
          state: EFinancingState.Valid,
        }
      }
    },

    async fetchIncomesCalculationRecommendation(): Promise<void> {
      this.incomesCalculationRecommendation = await getIncomesCalculationRecommendation()
    },

    async fetchScoring(): Promise<void> {
      this.scoring = structuredClone(await getScoring())
    },

    async fetchInterrogations(): Promise<void> {
      this.interrogations = structuredClone(await getInterrogations())
    },

    async fetchPrequalifications(): Promise<void> {
      this.prequalifications = structuredClone(await getPrequalifications())
    },

    async fetchDocuments(): Promise<void> {
      this.areDocumentsLoading = true
      const documents = await getDocuments()

      this.documents = structuredClone(documents)
      this.areDocumentsLoading = false
    },

    async fetchBankList(): Promise<void> {
      const bankList = await getBankList()

      this.banks = structuredClone(bankList.toSorted((bankA, bankB) => bankA.name.localeCompare(bankB.name)))
    },

    async fetchBankAccounts(bankSlug: string): Promise<void> {
      const bankAccounts = await getBankAccounts(bankSlug)

      this.bankAccounts = uniqBy([...structuredClone(bankAccounts), ...this.bankAccounts], 'id')
    },

    async fetchBankContacts(bankSlug: string): Promise<IBankContact[]> {
      const contacts = await getBankContacts(bankSlug)

      this.bankContacts = uniqBy([...structuredClone(contacts), ...this.bankContacts], 'id')

      return structuredClone(contacts)
    },

    async fetchNotifications(): Promise<void> {
      this.notifications = await getNotifications()
    },

    async fetchLastDocNotificationAt(): Promise<void> {
      this.deal.lastDocNotificationAt = await getLastDocNotificationAt()
    },

    async fetchBankStatementsAnalysis(): Promise<void> {
      const bankStatementsAnalysis = await getBankStatementsAnalysis()

      this.bankStatementsAnalysis = structuredClone(bankStatementsAnalysis)
    },

    async fetchBroker(): Promise<void> {
      this.broker = await getBroker()
    },

    async updateBankStatementsAnalysisCollection(): Promise<void> {
      const bankStatementsAnalysisCollection: IBankStatementsAnalysisInput[] = this.bankStatementsAnalysis.map(
        (bankStatementsAnalysis) => ({
          id: bankStatementsAnalysis.id,
          synthesis: bankStatementsAnalysis.synthesis,
        }),
      )

      await useBankStatementService().updateBankStatementsAnalysisCollection(bankStatementsAnalysisCollection)
    },

    async fetchDigitalInsureProjectId(): Promise<void> {
      const digitalInsureProjectId = await getDigitalInsureProjectId()

      this.deal.digitalInsureProjectId = digitalInsureProjectId
    },
  },
})
