import { useMutation, useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import BankContactsQuery from '@/schemas/Preparation/BankContacts.query.gql'
import CreateBankContactMutation from '@/schemas/Preparation/CreateBankContact.mutation.gql'
import UpdateBankContactMutation from '@/schemas/Preparation/UpdateBankContact.mutation.gql'

import IBankContact from '@/types/BankContact.interface'
import IBankContactsQuery from '@/types/schemas/Preparation/BankContactsQuery.interface'
import ICreateBankContactMutation from '@/types/schemas/Preparation/CreateBankContactMutation.interface'
import INewContact from '@/types/NewContact.interface'
import IUpdateBankContactMutation from '@/types/schemas/Preparation/UpdateBankContactMutation.interface'

const { getProjectId } = useServiceHelper()

const getBankContacts = async (bankSlug: string): Promise<IBankContact[]> => {
  const {
    data: { contacts },
  } = await useQuery<IBankContactsQuery>({
    query: BankContactsQuery,
    variables: {
      id: getProjectId(),
      bankSlug,
    },
  })

  return contacts
}

const createBankContact = async (
  contact: INewContact,
): Promise<ICreateBankContactMutation['createBankContact'] | undefined> => {
  const { data } = await useMutation<ICreateBankContactMutation>({
    mutation: CreateBankContactMutation,
    variables: contact,
  })

  return data?.createBankContact
}

const updateBankContact = async (
  contactId: string,
  comment: string,
): Promise<IUpdateBankContactMutation['updateBankContact'] | undefined> => {
  const { data } = await useMutation<IUpdateBankContactMutation>({
    mutation: UpdateBankContactMutation,
    variables: {
      contactId: contactId,
      comment: comment,
    },
  })

  return data?.updateBankContact
}

export const useBankContactService = () => ({
  getBankContacts,
  createBankContact,
  updateBankContact,
})
