import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import BrokerageFeesQuery from '@/schemas/BrokerageFees.query.gql'

import IBrokerageFees from '@/types/BrokerageFees.interface'
import IBrokerageFeesQuery from '@/types/schemas/BrokerageFeesQuery.interface'

const { getProjectId } = useServiceHelper()

const getBrokerageFees = async (): Promise<IBrokerageFees> => {
  const { data } = await useQuery<IBrokerageFeesQuery>({
    query: BrokerageFeesQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return data.brokerageFees
}

export const useBrokerageFeesService = () => ({
  getBrokerageFees,
})
