import { App, InjectionKey, reactive } from 'vue'

export enum EEventType {
  Page = 'page',
  Track = 'track',
  NoTracking = 'no_tracking',
}

export enum EEventAction {
  Clicked = 'clicked',
  Dragged = 'dragged',
  Hover = 'hover',
  NoTracking = 'no_tracking',
  Toggled = 'toggled',
  Viewed = 'viewed',
}

export interface IEvent {
  object: string
  action: EEventAction
  type: EEventType
}

export const events = {
  noTracking: {
    object: 'no-tracking',
    action: EEventAction.NoTracking,
    type: EEventType.NoTracking,
  },
  logo: {
    object: 'logo',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  featureFlag: {
    object: 'feature-flag',
    action: EEventAction.Toggled,
    type: EEventType.Track,
  },
  save: {
    object: 'save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  headerFeedback: {
    object: 'header-feedback',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  headerNav: {
    object: 'header-nav-item',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  headerMortgagorCallPhoneNumber: {
    object: 'header-mortgagor-call-phone-number',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  headerMortgagorCopyPhoneNumber: {
    object: 'header-mortgagor-copy-phone-number',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  clientToggle: {
    object: 'client-toggle',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationTrayTrigger: {
    object: 'notification-tray-trigger',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationTrayClose: {
    object: 'notification-tray-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationItemApprove: {
    object: 'notification-item-approve',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationItemDeny: {
    object: 'notification-item-deny',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationItemLinkToDoc: {
    object: 'notification-item-link-to-doc',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationItemLinkToField: {
    object: 'notification-item-link-to-field',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationItemClose: {
    object: 'notification-item-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationPopupApprove: {
    object: 'notification-popup-approve',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationPopupCancel: {
    object: 'notification-popup-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  notificationPopupClose: {
    object: 'notification-popup-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  activityHistoryOpenFeedback: {
    object: 'activity-history-open-feedback',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  activityHistoryCloseFeedback: {
    object: 'activity-history-close-feedback',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  subHeaderProfileAdd: {
    object: 'subheader-profile-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  subHeaderBudgetToggle: {
    object: 'subheader-budget-toggle',
    action: EEventAction.Toggled,
    type: EEventType.Track,
  },
  subHeaderConsultancyButton: {
    object: 'subheader-consultancy-button',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  localisationFieldAction: {
    object: 'localisation-field-action',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  sidebarCardOpen: {
    object: 'sidebar-card-open',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  sidebarCardClose: {
    object: 'sidebar-card-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  sidebarCardTooltip: {
    object: 'sidebar-card-tooltip',
    action: EEventAction.Hover,
    type: EEventType.Track,
  },
  summaryOpenAll: {
    object: 'summary-open-all',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  summaryCloseAll: {
    object: 'summary-close-all',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  summaryOpen: {
    object: 'summary-open',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  summaryClose: {
    object: 'summary-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectNav: {
    object: 'project-nav-item',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  preparationNav: {
    object: 'preparation-nav-item',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectKindDropdown: {
    object: 'project-kind-dropdown',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  projectKindCancel: {
    object: 'project-kind-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectKindValidate: {
    object: 'project-kind-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCompletion: {
    object: 'project-completion',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCompetitionAddBank: {
    object: 'project-competition-add-bank',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCompetitionAddBroker: {
    object: 'project-competition-add-broker',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCompetitionDelete: {
    object: 'project-competition-delete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCostsDisclaimerCollapse: {
    object: 'project-costs-disclaimer-collapse',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCostsDisclaimerExpand: {
    object: 'project-costs-disclaimer-expand',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectPurchaseCapacityAction: {
    object: 'project-purchase-capacity-action',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCapacityPel: {
    object: 'extra-loan-open-capacity-modal',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectPurchaseContributionAction: {
    object: 'project-purchase-contribution-action',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectPurchaseNotaryButton: {
    object: 'project-purchase-notary-button',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCreditRemoveModalOk: {
    object: 'project-credit-remove-modal-ok',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectProfileAdd: {
    object: 'project-profile-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectProfileDelete: {
    object: 'project-profile-delete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectProfileSelfEmployement: {
    object: 'project-profile-self-employement',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectIncomesInfo: {
    object: 'project-incomes-info',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectExtraIncomeAdd: {
    object: 'project-extra-income-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectExtraIncomeDelete: {
    object: 'project-extra-income-delete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCustomIncome: {
    object: 'project-custom-income',
    action: EEventAction.Toggled,
    type: EEventType.Track,
  },
  projectCreditsAdd: {
    object: 'project-credits-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCreditsAddFromResult: {
    object: 'project-credits-add-from-result',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCreditsEstimate: {
    object: 'project-credits-estimate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectCreditsDelete: {
    object: 'project-credits-delete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectLinkBridgeButton: {
    object: 'project-link-bridge-button',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectLinkBridgeModalOk: {
    object: 'project-link-bridge-modal-ok',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectLinkBridgeModalClose: {
    object: 'project-link-bridge-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectImmovablesAdd: {
    object: 'project-immovables-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectImmovablesAddFromResult: {
    object: 'project-immovables-add-from-result',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectImmovablesDelete: {
    object: 'project-immovables-delete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectImmovablesIncomeAdd: {
    object: 'project-immovables-income-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectImmovablesIncomeDelete: {
    object: 'project-immovables-income-delete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectNewPropertyModalCreate: {
    object: 'project-new-property-modale-create',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectNewPropertyModalCancel: {
    object: 'project-new-property-modale-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectSavingsAdd: {
    object: 'project-savings-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectSavingsAddFromResult: {
    object: 'project-savings-add-from-result',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectSavingsDelete: {
    object: 'project-savings-delete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridView: {
    object: 'grid',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  gridRefresh: {
    object: 'grid-refresh',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridEligibleTresholdIncome: {
    object: 'grid-eligible-threshold-income',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridEligibleTresholdIncomeHover: {
    object: 'grid-eligible-threshold-income',
    action: EEventAction.Hover,
    type: EEventType.Track,
  },
  gridAddBank: {
    object: 'grid-add-bank',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankFilter: {
    object: 'grid-bank-filter',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridAddLoan: {
    object: 'grid-add-loan',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridDeleteLoan: {
    object: 'grid-delete-loan',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridEnableReinjectionLoan: {
    object: 'grid-enable-reinjection-loan',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridDisableReinjectionLoan: {
    object: 'grid-disable-reinjection-loan',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridEnableEcoPtzLoan: {
    object: 'grid-enable-eco-ptz-loan',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridDisableEcoPtzLoan: {
    object: 'grid-disable-eco-ptz-loan',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridCreateSteppedLoan: {
    object: 'grid-create-stepped-loan',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridSteppedLoanDisclaimerValidate: {
    object: 'grid-stepped-loan-disclaimer-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridSteppedLoanDisclaimerCancel: {
    object: 'grid-stepped-loan-disclaimer-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridSteppedLoanDisclaimerClose: {
    object: 'grid-stepped-loan-disclaimer-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridAddSteppedLoan: {
    object: 'grid-add-stepped-loan',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridDeleteSteppedLoan: {
    object: 'grid-delete-stepped-loan',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridInvalidStepStonlyGuide: {
    object: 'grid-invalid-step-stonly-guide',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankName: {
    object: 'grid-bank-name',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankFeedback: {
    object: 'grid-bank-feedback',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankModalOpenAirtable: {
    object: 'grid-bank-modal-open-airtable',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankModalSendFeedback: {
    object: 'grid-bank-modal-send-feedback',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankModalClose: {
    object: 'grid-bank-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankModalSeeMoreTags: {
    object: 'grid-bank-modal-see-more-tags',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankModalSeeLessTags: {
    object: 'grid-bank-modal-see-less-tags',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankModalSuggestion: {
    object: 'grid-bank-modal-suggestion',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  gridBankModalCancelFeedback: {
    object: 'grid-bank-modal-cancel-feedback',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  insuranceView: {
    object: 'insurance',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  insuranceCreateProject: {
    object: 'insurance-create-project',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  insuranceOpenProject: {
    object: 'insurance-open-project',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  insuranceRefreshGrid: {
    object: 'insurance-refresh-grid',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsView: {
    object: 'documents',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  documentsCategoriesView: {
    object: 'documents-categories',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  documentsListView: {
    object: 'documents-list',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  documentsOpenHistory: {
    object: 'documents-open-history',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAdd: {
    object: 'documents-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddItem: {
    object: 'documents-add-item',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFilterComplete: {
    object: 'documents-filter-complete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFilterIncomplete: {
    object: 'documents-filter-incomplete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCategoryStatus: {
    object: 'documents-category-status',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFilterValid: {
    object: 'documents-filter-valid',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFilterInvalid: {
    object: 'documents-filter-invalid',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFilterProcessing: {
    object: 'documents-filter-processing',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFilterArchived: {
    object: 'documents-filter-archived',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFilterIgnored: {
    object: 'documents-filter-ignored',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsOpenAll: {
    object: 'documents-open-all',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCloseAll: {
    object: 'documents-close-all',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsOpenCategory: {
    object: 'documents-open-category',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCloseCategory: {
    object: 'documents-close-category',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFileName: {
    object: 'documents-file-name',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFileStatus: {
    object: 'documents-file-status',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFileCategory: {
    object: 'documents-file-category',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsFileKind: {
    object: 'documents-file-kind',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsOpenFileCard: {
    object: 'documents-open-file-card',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsOpenFilePreview: {
    object: 'documents-open-file-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddFile: {
    object: 'documents-add-file',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsPreviewOpenLink: {
    object: 'documents-preview-open-link',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsPreviewClose: {
    object: 'documents-preview-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsPreviewPrevious: {
    object: 'documents-preview-previous',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsPreviewNext: {
    object: 'documents-preview-next',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsPriorizeProcessing: {
    object: 'documents-priorize-processing',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsSendNotification: {
    object: 'documents-send-notification',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddCategoryModalValidate: {
    object: 'documents-add-category-modal-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddCategoryModalCancel: {
    object: 'documents-add-category-modal-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddCategoryModalClose: {
    object: 'documents-add-category-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddFileModalValidate: {
    object: 'documents-add-file-modal-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddFileModalCancel: {
    object: 'documents-add-file-modal-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddFileModalClose: {
    object: 'documents-add-file-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddFileModalAddFile: {
    object: 'documents-add-file-modal-add-file',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddFileModalRemoveFile: {
    object: 'documents-add-file-modal-remove-file',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsAddFileModalProcessing: {
    object: 'documents-add-file-modal-processing',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCardChangeView: {
    object: 'documents-card-change-view',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCardUploadedFile: {
    object: 'documents-card-uploaded-file',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCardProcessedFile: {
    object: 'documents-card-processed-file',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCardPreviewOpenLink: {
    object: 'documents-card-preview-open-link',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCardSubmit: {
    object: 'documents-card-submit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCardReportError: {
    object: 'documents-card-report-error',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCardClose: {
    object: 'documents-card-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCardPrevious: {
    object: 'documents-card-previous',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  documentsCardNext: {
    object: 'documents-card-next',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  certificateView: {
    object: 'certificate',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  certificatePreview: {
    object: 'certificate-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  certificateRevoke: {
    object: 'certificate-revoke',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  certificateDownload: {
    object: 'certificate-download',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  certificateValidate: {
    object: 'certificate-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  mandateView: {
    object: 'mandate',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  mandatePreview: {
    object: 'mandate-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementView: {
    object: 'agreement',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  agreementsView: {
    object: 'agreements',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  agreementsEmptyListEditInterrogations: {
    object: 'agreements-empty-list-edit-interrogations',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementCardSync: {
    object: 'agreement-card-sync',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementCardEdit: {
    object: 'agreement-card-edit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementCardPreview: {
    object: 'agreement-card-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  preflowAgreementModalValidate: {
    object: 'preflow-agreement-modal-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  preflowAgreementModalClose: {
    object: 'preflow-agreement-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  prepareAgreementModalCancel: {
    object: 'prepare-agreement-modal-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  prepareAgreementModalNext: {
    object: 'prepare-agreement-modal-next',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  preprareAgreementModalUpdateRate: {
    object: 'prepare-agreement-modal-update-rate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementModalSave: {
    object: 'edit-agreement-modal-save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementModalPrevious: {
    object: 'edit-agreement-modal-previous',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementModalClose: {
    object: 'edit-agreement-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementModalAddComment: {
    object: 'edit-agreement-modal-add-comment',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementModalAddItem: {
    object: 'edit-agreement-modal-add-item',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementModalRemoveItem: {
    object: 'edit-agreement-modal-remove-item',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementModalResetTable: {
    object: 'edit-agreement-modal-reset-table',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementDisclaimerValidate: {
    object: 'edit-agreement-disclaimer-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementDisclaimerCancel: {
    object: 'edit-agreement-disclaimer-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  editAgreementDisclaimerClose: {
    object: 'edit-agreement-disclaimer-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  previewAgreementModalPrevious: {
    object: 'preview-agreement-modal-previous',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  previewAgreementModalSend: {
    object: 'preview-agreement-modal-send',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementPreview: {
    object: 'agreement-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementPrepare: {
    object: 'agreement-prepare',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementResync: {
    object: 'agreement-resync',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementDemote: {
    object: 'agreement-demote',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementModalRefreshOffers: {
    object: 'agreement-modal-refresh-offers',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementModalSave: {
    object: 'agreement-modal-save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementModalPreview: {
    object: 'agreement-modal-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  agreementModalSend: {
    object: 'agreement-modal-send',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationView: {
    object: 'interrogation',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  prequalificationsView: {
    object: 'prequalifications',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  interrogationCreate: {
    object: 'interrogation-create',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationHistory: {
    object: 'interrogation-history',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  boursoRequestDownload: {
    object: 'bourso-request-download',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationMortgageRequestPreview: {
    object: 'interrogation-mortgage-request-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationResubmit: {
    object: 'interrogation-resubmit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationResubmitModalResubmit: {
    object: 'interrogation-resubmit-modal-resubmit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationResubmitModalContinue: {
    object: 'interrogation-resubmit-modal-continue',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationReopen: {
    object: 'interrogation-reopen',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationDrop: {
    object: 'interrogation-drop',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationResponsesSubmit: {
    object: 'interrogation-responses-submit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationTransmissionCreate: {
    object: 'interrogation-transmission-create',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationTransmissionDetails: {
    object: 'interrogation-transmission-details',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  interrogationStepApprovalCommentSave: {
    object: 'interrogation-step-approval-comment-save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepContactBankProcess: {
    object: 'interrogation-step-contact-bank-process',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepContactNext: {
    object: 'interrogation-step-contact-next',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepTransmissionEditSendMode: {
    object: 'interrogation-step-transmission-edit-send-mode',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepTransmissionBankProcess: {
    object: 'interrogation-step-transmission-bank-process',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepTransmissionNext: {
    object: 'interrogation-step-transmission-next',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepDocsList: {
    object: 'interrogation-step-docs-list',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepDocsDocPreview: {
    object: 'interrogation-step-docs-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepDocsSave: {
    object: 'interrogation-step-docs-save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepDocsSend: {
    object: 'interrogation-step-docs-send',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepPreviewCarcassePreview: {
    object: 'interrogation-step-preview-carcasse-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepPreviewDocPreview: {
    object: 'interrogation-step-preview-doc-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepPreviewEdit: {
    object: 'interrogation-step-preview-edit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepPreviewSend: {
    object: 'interrogation-step-preview-send',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationStepAuditsSave: {
    object: 'interrogation-step-audits-save',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationCheckList: {
    object: 'interrogation-checklist',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  transmissionEdit: {
    object: 'transmission-edit',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  transmissionIdCopy: {
    object: 'transmission-id-copy',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  transmissionDrop: {
    object: 'transmission-drop',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankContactPanel: {
    object: 'bank-contact',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  bankContactItemPanel: {
    object: 'bank-contact-item',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  bankContactOrganizationButton: {
    object: 'bank-contact-organization-button',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankContactBackButton: {
    object: 'bank-contact-back-button',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankContactReportAction: {
    object: 'bank-contact-report',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankContactCallPhoneNumber: {
    object: 'bank-contact-call-phone-number',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankContactCopyPhoneNumber: {
    object: 'bank-contact-copy-phone-number',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  duplicateBankContactContinue: {
    object: 'duplicate-bank-contact-continue',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  duplicateBankContactSendFeedback: {
    object: 'duplicate-bank-contact-send-feedback',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  duplicateBankContactCancel: {
    object: 'duplicate-bank-contact-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  organizationListPanel: {
    object: 'bank-contact-organization-list',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  organizationItemPanel: {
    object: 'bank-contact-organization-item',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  projectSpecificAmountDelete: {
    object: 'project-specific-amount-delete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  projectSpecificAmountAdd: {
    object: 'project-specific-amount-add',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  calculatorView: {
    object: 'calculator',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  budgetCalculatorCalculate: {
    object: 'budget-calculator-calculate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  budgetCalculatorApply: {
    object: 'budget-calculator-apply',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  budgetCalculatorCopy: {
    object: 'budget-calculator-copy',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  historyView: {
    object: 'history',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  clientView: {
    object: 'client',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  saveStrategy: {
    object: 'save-strategy',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  validateStrategy: {
    object: 'validate-strategy',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  previewerOpenLink: {
    object: 'previewer-open-link',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  oversizedDocModalClose: {
    object: 'oversized-doc-modal-keep-doc',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  oversizedDocModalOk: {
    object: 'oversized-doc-modal-remove-doc',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  prequalificationCreate: {
    object: 'prequalification-create',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  prequalificationDownload: {
    object: 'prequalification-download',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  prequalificationReload: {
    object: 'prequalification-reload',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  interrogationCreateFromPrequalification: {
    object: 'interrogation-create-from-prequalification',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  digitalInsureFallback: {
    object: 'digital-insure-fallback',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  calculatePel: {
    object: 'extra-loan-calculate-pel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  completePel: {
    object: 'extra-loan-complete-pel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  choiceAmountPel: {
    object: 'extra-loan-complete-pel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  loadFullGrid: {
    object: 'full-loading',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  loadLimitedGrid: {
    object: 'filtered-loading',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  layoutDivider: {
    object: 'layout-divider',
    action: EEventAction.Dragged,
    type: EEventType.Track,
  },
  blockHeaderExpand: {
    object: 'block-header-expand',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  consultancyPreviousStep: {
    object: 'consultancy-previous-step',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  consultancyNextStep: {
    object: 'consultancy-next-step',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  consultancyGenerateDocument: {
    object: 'consultancy-generate-document',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisView: {
    object: 'synthesis',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  synthesisFill: {
    object: 'synthesis-fill',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisFillValidate: {
    object: 'synthesis-fill-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiGenerate: {
    object: 'synthesis-ai-generate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiOpenParameters: {
    object: 'synthesis-ai-open-parameters',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiSwitchView: {
    object: 'synthesis-ai-switch-view',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisOldSwitchView: {
    object: 'synthesis-old-switch-view',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiStopStream: {
    object: 'synthesis-ai-stop-stream',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiCloseParameters: {
    object: 'synthesis-ai-close-parameters',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiSelectFormat: {
    object: 'synthesis-ai-select-format',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiSelectFormality: {
    object: 'synthesis-ai-select-formality',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiSelectHighlights: {
    object: 'synthesis-ai-select-highlights',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiDeleteHighlight: {
    object: 'synthesis-ai-delete-highlight',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiSelectWarnings: {
    object: 'synthesis-ai-select-warnings',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiDeleteWarning: {
    object: 'synthesis-ai-delete-warning',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiSelectConcessions: {
    object: 'synthesis-ai-select-concessions',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiDeleteConcession: {
    object: 'synthesis-ai-delete-concession',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiInputFocus: {
    object: 'synthesis-ai-input-focus',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  synthesisAiOutputFocus: {
    object: 'synthesis-ai-output',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsView: {
    object: 'bank-statements',
    action: EEventAction.Viewed,
    type: EEventType.Page,
  },
  bankStatementsNoDocumentGuide: {
    object: 'bank-statements-no-document-guide',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsDocuments: {
    object: 'bank-statements-documents',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsAddToRequest: {
    object: 'bank-statements-add-to-request',
    action: EEventAction.Toggled,
    type: EEventType.Track,
  },
  bankStatementsRunAnalysis: {
    object: 'bank-statements-run-analysis',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsReloadAnalysis: {
    object: 'bank-statements-reload-analysis',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsItemRunAnalysis: {
    object: 'bank-statements-item-run-analysis',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsDisclaimerCancel: {
    object: 'bank-statements-disclaimer-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsDisclaimerClose: {
    object: 'bank-statements-disclaimer-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsDisclaimerContinue: {
    object: 'bank-statements-disclaimer-continue',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsOpenPreview: {
    object: 'bank-statements-open-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsClosePreview: {
    object: 'bank-statements-close-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsChangePreview: {
    object: 'bank-statements-change-preview',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  bankStatementsHelp: {
    object: 'bank-statements-help',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  accessBankContactDetails: {
    object: 'access-bank-contact-details',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  modifyCommentBankContactDetails: {
    object: 'modify-comment-bank-contact-details',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  validateModifyCommentBankContactDetails: {
    object: 'validate-modify-comment-bank-contact-details',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  cancelModifyCommentBankContactDetails: {
    object: 'cancel-modify-comment-bank-contact-details',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  changelogRevert: {
    object: 'changelog-revert',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestsDropdown: {
    object: 'requests-dropdown',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestsDropdownItem: {
    object: 'requests-dropdown-item',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestsDropdownAddRequest: {
    object: 'requests-dropdown-add-request',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestsCreateModalValidate: {
    object: 'requests-create-modal-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestsCreateModalClose: {
    object: 'requests-create-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDropdownItemSetPrimary: {
    object: 'request-dropdown-item-set-primary',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestSetAsPrimaryModalValidate: {
    object: 'request-set-as-primary-modal-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestSetAsPrimaryModalClose: {
    object: 'request-set-as-primary-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestSetAsPrimaryModalCancel: {
    object: 'request-set-as-primary-modal-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDropdownItemRename: {
    object: 'request-dropdown-item-rename',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestRenameModalValidate: {
    object: 'request-rename-modal-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestRenameModalClose: {
    object: 'request-rename-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestRenameModalCancel: {
    object: 'request-rename-modal-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDropdownItemDuplicate: {
    object: 'request-dropdown-item-duplicate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDuplicateModalValidate: {
    object: 'request-duplicate-modal-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDuplicateModalClose: {
    object: 'request-duplicate-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDuplicateModalCancel: {
    object: 'request-duplicate-modal-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDropdownItemDelete: {
    object: 'request-dropdown-item-delete',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDeleteModalValidate: {
    object: 'request-delete-modal-validate',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDeleteModalClose: {
    object: 'request-delete-modal-close',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
  requestDeleteModalCancel: {
    object: 'request-delete-modal-cancel',
    action: EEventAction.Clicked,
    type: EEventType.Track,
  },
}

// Type is here for autocompletion inside Vue files
export type TTrackedEvents = Record<keyof typeof events, IEvent>

export const trackingKey = Symbol() as InjectionKey<TTrackedEvents>

export const createEvents = () => ({
  install(app: App) {
    app.config.globalProperties.$trackedEvents = reactive(events)

    app.provide<TTrackedEvents>(trackingKey, events)
  },
})
