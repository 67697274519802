import { useQuery } from '@/plugins/apollo'

import BankDetailsQuery from '@/schemas/Preparation/BankDetails.query.gql'
import BankListQuery from '@/schemas/Preparation/BankList.query.gql'

import IBankDetails from '@/types/BankDetails.interface'
import IBankDetailsQuery from '@/types/schemas/Preparation/BankDetailsQuery.interface'
import IBankListQuery from '@/types/schemas/Preparation/BankListQuery.interface'

const getBankDetails = async (bankSlug: string): Promise<IBankDetails> => {
  const {
    data: { bankDetails },
  } = await useQuery<IBankDetailsQuery>({
    query: BankDetailsQuery,
    variables: {
      bankSlug,
    },
    fetchPolicy: 'cache-first',
  })

  return bankDetails
}

const getBankList = async (): Promise<IBankDetails[]> => {
  const {
    data: { bankList },
  } = await useQuery<IBankListQuery>({
    query: BankListQuery,
    fetchPolicy: 'cache-first',
  })

  return bankList
}

export const useBankService = () => ({
  getBankDetails,
  getBankList,
})
